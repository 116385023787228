@import './style/variables.scss';
@import './libs/bootstrap/scss/bootstrap.scss';
@import "@elements/datepicker/scss/datepicker";
@import "swiper/css";
@import "swiper/css/autoplay";
@import "@elements/formvalidation.io/dist/css/formValidation";
@import "@elements/range-slider/dist/range-slider";
@import "lightgallery/scss/lg-zoom";
@import "lightgallery/scss/lg-video";
@import "lightgallery/scss/lg-thumbnail";
@import '@elements/lightbox/scss/lightbox.scss';
@import 'blaze-slider/dist/blaze';