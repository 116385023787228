// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
    display: inline-block;
    padding: calc(8rem / 16) calc(12rem / 16) calc(7rem / 16);
    @include font-size($badge-font-size);
    line-height: 1;
    color: $badge-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    letter-spacing: calc(1rem / 16);
    text-transform: uppercase;

    @include border-radius($badge-border-radius);

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    top: -1px;
}

.badge.badge--xs {
    font-size: calc(10rem / 16);
    padding: calc(7rem / 16) calc(8rem / 16);
}


.badge.badge--sm {
    text-transform: none;
    font-size: calc(13rem / 16);
    letter-spacing: 0;
    padding: calc(10rem / 16) calc(12rem / 16);
}

.badge--teaser {
    z-index: 1;
    position: absolute;
    top: calc(11rem/16);
    left: calc(11rem/16);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
}

.badge-teaser-container {
    z-index: 1;
    position: absolute;
    top: calc(11rem/16);
    left: calc(11rem/16);
    right: calc(30rem/16);
    display: flex;
    gap: calc(4rem / 16);
    flex-wrap: wrap;
}

.badge--shadow {
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
}