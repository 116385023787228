.pagination {
    display: flex;
    line-height: calc(22/15);
    @include list-unstyled();
}

.page-link {
    position: relative;
    display: block;
    color: $pagination-color;
    text-decoration: if($link-decoration == none, null, none);
    @include transition($pagination-transition);

    &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    &:focus {
        z-index: 3;
        color: $pagination-focus-color;
        outline: $pagination-focus-outline;
    }
    
    @media screen and (max-width: 767px){
        padding: 0 calc(6rem/16);
    }
}
.pagination-sm .page-link{
    @media screen and (max-width: 767px){
        font-size: calc(13rem/16);
    }
}

.page-item {
    &:not(:first-child) .page-link {
        margin-left: $pagination-margin-start;
    }

    &.active .page-link {
        z-index: 3;
        color: $pagination-active-color;
        @include gradient-bg($pagination-active-bg);
        border-color: $pagination-active-border-color;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}
.page-item--arrow .page-link{
    color: var(--color-dark);
}
.page-link__icon{
    font-size: calc(5rem/16);
    position: relative;
    top: calc(-2rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(7rem/16);
        margin: 0 calc(20rem/16);
    }
}
.pagination-sm .page-link__icon{
    margin: 0;
}
.disabled.page-item--arrow .page-link{
    color: var(--color-grey-50);
}

//
// Sizing
//
@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-border-radius);

//.pagination-lg {
//    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $pagination-border-radius-lg);
//}

.pagination-sm {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $pagination-border-radius-sm);
}
