.toast {
    position: relative;
    max-width: $toast-max-width;
    width: 100%;
    @include font-size($toast-font-size);
    color: $toast-color;
    pointer-events: auto;
    background-color: $toast-background-color;
    background-clip: padding-box;
    box-shadow: 0 0 calc(12rem / 16) 0 rgba(62,61,64,0.2);
    @include border-radius($toast-border-radius);
    padding: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(44rem / 16);
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.2);
    }

    &.showing {
        opacity: 0;
    }

    &:not(.show) {
        display: none;
    }
}

.toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none;
}
.alert-notification .toast + .toast,
.toast-container .toast + .toast {
    margin-top: $toast-spacing;
}

.toast-header {
    display: flex;
    align-items: baseline;
    color: $toast-header-color;
    background-clip: padding-box;
}

.toast-header + .toast-body {
    margin-top: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(44rem / 16);
    }
}
.toast-body {
    word-wrap: break-word;
}

.toast__close {
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: 0;
    color: var(--color-grey-50);
    font-size: calc(14rem / 16);
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(12rem / 16) calc(16rem / 16);
    }
}
.toast-header__icon {
    font-size: calc(14rem / 16);
    margin-right: calc(5rem / 16);
    position: relative;
    top: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
        margin-right: calc(9rem / 16);
    }
}

.toast.toast-sm {
    padding: calc(22rem / 16);
}