.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
    margin-bottom: $breadcrumb-margin-bottom;
    font-size: calc(11rem/16);
    list-style: none;
    background-color: $breadcrumb-bg;
    @include border-radius($breadcrumb-border-radius);
    letter-spacing: calc(0.5rem/16);
    @media screen and (min-width: 768px){
        @include font-size($breadcrumb-font-size);
    }
}

.breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    display: flex;
    align-items: center;
    + .breadcrumb-item {
        padding-left: calc(8rem/16);
        @media screen and (min-width: 768px){
            padding-left: $breadcrumb-item-padding-x;
        }

        &::before {
            float: left; // Suppress inline spacings and underlining of the separator
            padding-bottom: calc(8rem/16);
            color: $breadcrumb-divider-color;
            content: var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider-flipped)) #{"*/"};
            font-family: iconfont;
            transform: rotate(-90deg);
            font-size: calc(5rem/16);
            opacity: 0.5;
            @media screen and (min-width: 768px){
                padding-bottom: $breadcrumb-item-padding-x;
            }
        }
    }

    &.active {
        color: $breadcrumb-active-color;
    }
}
.breadcrumb-item .breadcrumb-item__icon{
    font-size: calc(13rem/16);
    line-height: .9;

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
    }
}
