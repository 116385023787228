@media screen and (max-width: 767px) {
    @each $key, $ratio in $aspect-ratios-xs {
        .ratio-#{$key}-xs {
            --bs-aspect-ratio: #{$ratio};
        }
    }
}
.ratio-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}